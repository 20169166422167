import React, { FC } from 'react';
import { Breadcrumbs, Link } from '@material-ui/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStyles } from './BreadCrumb.styles';
import { VIEW_MODE } from '../EditDialog';

const BreadCrumb: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const pathnames = pathname.split('/').filter(Boolean);

  const capitalizeAfterHyphen = text => {
    const words = text.split('-');
    if (words.length > 1) {
      words[1] = words[1].charAt(0).toUpperCase() + words[1].slice(1);
    }
    return words.join(' ');
  };

  const isEditOrDetail =
    params.viewMode &&
    (params.viewMode.includes(VIEW_MODE.EDIT.toLowerCase()) ||
      params.viewMode.includes(VIEW_MODE.DETAILS.toLowerCase()));

  const getRouteName = (name: string) => {
    const modifiedName = name.toLowerCase();
    if (isEditOrDetail && (modifiedName === 'edit' || modifiedName === 'details')) {
      return 'Details';
    }
    return name.charAt(0).toUpperCase() + capitalizeAfterHyphen(name.slice(1));
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      {pathnames.map((name, index) => {
        if (!isNaN(Number(name)) || name.includes('upsert')) {
          return null;
        }
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        const routeName = getRouteName(name);
        if (routeName !== '') {
          return (
            <Link
              className={isLast ? classes.activeRoute : classes.nonActiveRoute}
              key={name}
              onClick={() => (isLast ? '' : navigate(routeTo))}
            >
              {getRouteName(name)}
            </Link>
          );
        }
        return null;
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
