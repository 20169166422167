export enum NO_SQL_COLLECTIONS {
  CITY = 'City',
  STATE = 'State',
  ISLAND = 'Island',
  COUNTRY = 'Country',
  FIR = 'FIR',
  WORLDEVENT = 'WorldEvent',
  Airports = 'Airport',
  AIRPORT_HOUR = 'AirportHour',
  PERMIT = 'Permit',
  HEALTH_AUTHORIZATION = 'HealthAuthorization',
  FLIGHT_PLAN_FORMAT = 'FlightPlanFormat',
  FLIGHT_PLANNING_SERVICE = 'CustomersWithNonStandardRunwayAnalysis',
  CUSTOMERS = 'Customers',
  TYPE_AHEAD_AIRPORT = 'ATSAirports',
  HEALTH_VENDOR = 'HealthVendor',
  AIRPORT_HOUR_REMARKS = 'AirportHoursRemark',
  AIRPORT_HOUR_SUBTYPES = 'AirportHoursSubType',
  AIRPORT_CONDITIONAL_OPERATOR = 'AirportHoursConditionalOperator',
  AIRPORT_CONDITION_TYPE = 'AirportHoursConditionType',
  AIRPORT_HOUR_TYPES = 'AirportHoursType',
  AIRPORT_SCHEDULE_TYPE = 'AirportHoursScheduleType',
  REGION = 'Region',
  AIRPORT_TYPE = 'AirportType',
  AIRPORT_FACILITY_TYPE = 'AirportFacilityType',
  DISTANCE_UOM = 'DistanceUOM',
  AIRPORT_DIRECTION = 'AirportDirection',
  AIRPORT_USAGE_TYPE = 'AirportUsageType',
  SCHEDULE_RESTRICTION = 'ScheduleRestriction',
  AIRCRAFT_OPERATOR_RESTRICTION = 'AircraftOperatorRestriction',
  LEAD_TIME_TYPE = 'LeadTimeType',
  NAV_BLUE_GENERIC_REGISTRY = 'NavBlueGenericRegistry',
  AIRPORT_FACILITY_ACCESS_LEVEL = 'AirportFacilityAccessLevel',
  ICAO_CODE = 'ICAOCode',
  UA_OFFICE = 'UAOffice',
  MILITARY_USE_TYPE = 'MilitaryUseType',
  AIRPORT_DATA_SOURCE = 'AirportDataSource',
  AIRPORT_HOURS_BUFFER = 'AirportHoursBuffer',
  AIRCRAFT_VARIATION = 'AircraftVariation',
  PERFORMANCE = 'Performance',
  RUNWAY_SURFACE_TYPE = 'RunwaySurfaceType',
  RUNWAY_CONDITION = 'RunwayCondition',
  RUNWAY_SURFACE_TREATMENT = 'RunwaySurfaceTreatment',
  RUNWAY_LIGHT_TYPE = 'RunwayLightType',
  RUNWAY_RVR = 'RunwayRVR',
  RUNWAY_VGSI = 'RunwayVGSI',
  RUNWAY_APPROACH_LIGHT = 'RunwayApproachLight',
  PERMIT_VALIDITY = 'PermitValidity',
  WEATHER_REPORTING_SYSTEMS = 'WeatherReportingSystem',
  AIRPORT_CLASS_CODE = 'ClassCode',
  AIRPORT_CERTIFICATE_CODE = 'CertificateCode',
  AIRPORT_SERVICE_CODE = 'ServiceCode',
  AIRPORT_CATEGORY = 'AirportCategory',
  RUNWAY_NAVAIDS = 'RunwayNavaids',
  RUNWAY_APPROACH_TYPE = 'RunwayApproachType',
  RUNWAY_USAGE_TYPE = 'RunwayUsageType',
  AIRPORT_OF_ENTRY = 'AirportOfEntry',
  AIP = 'AeronauticalInformationPublication',
  AIRPORT_BULLETIN = 'AirportBulletin',
  COUNTRY_BULLETIN = 'CountryBulletin',
  REGISTRY = 'Registry',
  CUSTOMER = 'Customer',
  OPERATOR = 'Operator',
  ASSOCITED_SITES = 'AssociatedSites',
}
